html {
  height: 100%;
  background: #fff;
}

body {
  margin: 0;
  font-family: 'Helvetica', 'Amatic SC', 'sans-serif';
}

.main-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background: #fff;
  height: 100vh;
}

.clock-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
